:root {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}

html {
  scroll-behavior: smooth;
}
